(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

require("./vendors/h5bp");

/*
 * creación del mapa con als ubicaciones
 *
 * - Ejecucion en: $(document).ready()
 */
function goOffices() {
  var markers = [["<p> <b>Tel:</b> 0343 571-1197 <br><b>Mail:</b> destileriadellitoral@gmail.com <br> Paraná, Entre Rios, Argentina</p>", -31.771352132880683, -60.45156289168091]];

  var latlng = new google.maps.LatLng(-31.771352132880683, -60.45156289168091);
  var mapOptions = {
    zoom: 14,
    center: latlng,
    mapTypeControl: false,
    scaleControl: false,
    panControl: false,
    rotateControl: false,
    zoomControl: false,
    streetViewControl: false,
    scrollwheel: false,
    styles: [{ featureType: "landscape", stylers: [{ color: "#ffffff" }] }, {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#cfa878" }]
    }, {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: "#008996" }]
    }, { featureType: "water", stylers: [{ color: "#cfa878" }] }]
  };
  var map = new google.maps.Map(document.getElementById("map-canvas"), mapOptions);
  var infowindow = new google.maps.InfoWindow();
  var marker;
  var i;
  for (i = 0; i < markers.length; i++) {
    marker = new google.maps.Marker({
      position: new google.maps.LatLng(markers[i][1], markers[i][2]),
      map: map,
      icon: "./assets/media/location-pin.png"
    });
    // google.maps.event.addListener(marker, 'click', (function (marker, i) {
    // return function () {
    infowindow.setContent(markers[i][0]);
    infowindow.open(map, marker);
    // };
    // })(marker, i));
  }
}

/*
 * Envío del formulario de contacto
 *
 * - Ejecucion en: $(document).ready()
 */

function contactFormGo() {
  $("#contact-submit").on("click", function (e) {
    //formulario de contacto
    e.preventDefault();

    var okflag = true;
    $("input,textarea").filter("[required]:visible").each(function (i, requiredField) {
      if ($(requiredField).val() == "") {
        if (okflag == true) $("#msg-all").fadeIn().delay(5000).fadeOut();
        okflag = false;
        $(requiredField).addClass("contact-error");
      } else $(requiredField).removeClass("contact-error");
    });

    if (okflag == true) $("#msg").addClass("backGload").fadeIn().load("contacto.php", {
      nombre: $("#contact-name").val(),
      telefono: $("#contact-phone").val(),
      email: $("#contact-email").val(),
      mensaje: $("#contact-message").val()
    }, function () {
      $("input, textarea").val("");
      $("#msg").removeClass("backGload");
    });
  });
}

/*
 * Ejecuto funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
// function goMaterialize() {
//   $('.slider').slider({height:500});
//   $('.slider2').slider({height:400});
//   $(".button-collapse").sideNav();
// }

/*
 * Ejecuto funciones de materialize
 *
 * - Ejecucion en: $(document).ready()
 */
//  function goSliders() {

//   $(".img-slider").slick({
//     arrows: true,
//     dots: true,
//     autoplay: true
//   });

//   $(".latest-news").slick({
//     arrows: false,
//     dots: true,
//     autoplay: true
//   });

// }

/*
 *
 * Ejecuto el llamado de todas las funciones Nac y Pop
 *
 */
$(document).ready(function () {
  // goSliders();
  // goMaterialize();

  contactFormGo();
  goOffices();
});

},{"./vendors/h5bp":2}],2:[function(require,module,exports){
'use strict';

// Avoid `console` errors in browsers that lack a console.
(function () {
  'use strict';

  var method;
  var noop = function noop() {};
  var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'];
  var length = methods.length;
  var console = window.console = window.console || {};

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
})();

},{}]},{},[1])

